import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "multi-select",
      "style": {
        "position": "relative"
      }
    }}>{`Multi Select`}</h1>
    <p>{`Use the multi-select component to select more than 1 item from a list. The list usually contains 5 or more items. It allows to search and shows the items that have already been selected or select all items at once.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`Order the list by frequency of use, and if applicable, in ascending order.`}</li>
      <li parentName="ul">{`If the list shows less than 5 items, consider displaying directly on the screen, using the `}<a parentName="li" {...{
          "href": "/components/checkbox"
        }}>{`Checkbox`}</a>{` component.`}</li>
      <li parentName="ul">{`Whenever possible group the options and use short labels to describe the content (E.g.: In a food combo, the options can be grouped by: Vegetable, fruits, etc.)`}</li>
      <li parentName="ul">{`For a situation where the user can select only one option, use `}<a parentName="li" {...{
          "href": "/components/select"
        }}>{`Select`}</a>{` or `}<a parentName="li" {...{
          "href": "/components/radio-button"
        }}>{`Radio Button`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}</h2>
    <Demo src='pages/components/multi-select/MultiSelect' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      